import ResumePDF from "../resume.pdf";

function IntroSection() {
  return (
    <div className="flex-auto">
      <p>
        Hi!
        <strong className="text-cyan-400"> I'm Trevor</strong>
        <br></br>
        <br></br>
        I'm a<strong className="text-cyan-400"> forward-thinking</strong>,
        <strong className="text-indigo-400"> polyglot </strong>Software Engineer
        with experience in creating mobile and web applications using languages
        such as
        <strong className="text-[#0074D9]"> PHP</strong>,
        <strong className="text-[#FFAC45]"> Swift</strong>,
        <strong className="text-[#3776AB]"> Python</strong>,
        <strong className="text-[#CC342D]"> Ruby</strong>,
        <strong className="text-[#00ADD8]"> Golang</strong>,
        <strong className="text-[#F7DF1E]"> JavaScript</strong>,
        <strong className="text-[#007ACC]"> TypeScript</strong>,
        <strong className="text-[#1E90FF]"> SQL</strong>,
        <strong className="text-[#8BC34A]"> C#</strong>, and
        <strong className="text-[#E10098]"> GraphQL</strong>.
        <br />
        <br />
        <span>
          You can
          <a
            href="mailto:coding@trevorphillips.dev?subject=🔥 Hiring Request Form 🔥"
            target="_blank"
            rel="noreferrer"
          >
            <strong className="text-cyan-400"> Hire Me </strong>
          </a>
          or view my
          <a href={ResumePDF} target="_blank" rel="noreferrer">
            <strong className="text-cyan-400"> Resume</strong>
          </a>
          .
        </span>
      </p>
    </div>
  );
}

export default IntroSection;
